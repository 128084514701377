import React from "react"
import { Link } from "gatsby"
import { Box, Badge, Image, Grid } from "@chakra-ui/core"
import Layout from "../../../components/layoutPhoenixCustom"
import BgImg from "../../../images/RomansPracticeBig.jpeg"
import Romans from "../../../images/practiceTitle.jpeg"
import Friends from "../../../images/friendsDayEvent.jpeg"
import More from "../../../images/moreSermons.jpg"
import SEO from "../../../components/seo"
import { css } from "@emotion/core"

function SermonBox({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as={Link}
      to={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

function SermonBoxExternal({
  imageUrl,
  imageAlt,
  sermonTitle,
  sermonLink,
  date,
  badge,
  pastor,
  sermonSeries,
  badgeColor,
}) {
  return (
    <Box
      as="a"
      href={sermonLink}
      w="auto"
      h="auto"
      borderWidth="1px"
      rounded="lg"
      overflow="hidden"
      bg="#edf2f7"
      boxShadow="md"
      css={css`
        text-decoration: none;
        transition: 0.3s;
        &:hover {
          box-shadow: 4px 8px 16px 4px rgba(0, 0, 0, 0.2);
        }
      `}
    >
      <Image src={imageUrl} alt={imageAlt} w="100%" />

      <Box p="6">
        <Box alignItems="baseline">
          <Badge rounded="lg" px="8" py="2" variantColor={badgeColor} mt="0">
            {badge}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            mt="3"
          >
            {sermonSeries} &bull; {pastor}
          </Box>
        </Box>
        <Box
          fontWeight="bold"
          as="h2"
          lineHeight="tight"
          isTruncated
          color="gray.900"
        >
          {sermonTitle}
        </Box>

        <Box color="gray.600" fontSize="sm">
          {date}
        </Box>
      </Box>
    </Box>
  )
}

const IndexPage = () => (
  <Layout
    ImgSrc={BgImg}
    MainText="Romans: Practice"
    SecondText="Check out sermons from our Romans Sermon Series"
  >
    <SEO title="Sermons - Romans: Practice" />
    <Grid
      templateColumns={[
        "repeat(1, 1fr)",
        "repeat(1, 1fr)",
        "repeat(2, 1fr)",
        "repeat(3, 1fr)",
      ]}
      gap={10}
    >
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans: Practice"
        sermonTitle="Living out the Gospel"
        sermonSeries="Romans: Practice"
        sermonLink="/phoenix/sermons/living-out-the-gospel"
        date="April 10, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans: Practice"
        sermonTitle="A Prescription for the Church"
        sermonSeries="Romans: Practice"
        sermonLink="/phoenix/sermons/church-prescription"
        date="April 3, 2022"
        pastor="Scott Trefny"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans: Practice"
        sermonTitle="About or Against"
        sermonSeries="Romans: Practice"
        sermonLink="/phoenix/sermons/about-or-against"
        date="March 27, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans: Practice"
        sermonTitle="Is it a Sin?"
        sermonSeries="Romans: Practice"
        sermonLink="/phoenix/sermons/is-it-a-sin"
        date="March 20, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans: Practice"
        sermonTitle="Living in the Dark"
        sermonSeries="Romans: Practice"
        sermonLink="/phoenix/sermons/living-in-the-dark"
        date="March 13, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans: Practice"
        sermonTitle="Christian and Politics"
        sermonSeries="Romans: Practice"
        sermonLink="/phoenix/sermons/christian-and-politics"
        date="March 6, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Friends}
        imageAlt="Friends Day"
        sermonTitle="The Art of Friendship"
        sermonSeries="Friends Day"
        sermonLink="/phoenix/sermons/the-art-of-friendship"
        date="February 27, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Christian and Ethics"
        sermonSeries="Romans: Practice"
        sermonLink="/phoenix/sermons/christian-and-ethics"
        date="February 20, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Christian on Mission"
        sermonSeries="Romans: Practice"
        sermonLink="/phoenix/sermons/christian-on-mission"
        date="February 13, 2022"
        pastor="Kevin Ezell"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Christian and the Church"
        sermonSeries="Romans: Practice"
        sermonLink="/phoenix/sermons/christian-and-the-church"
        date="February 6, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Worship Through the Week"
        sermonSeries="Romans: Practice"
        sermonLink="/phoenix/sermons/worship-through-the-week"
        date="January 30, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="An Interlude of Praise"
        sermonSeries="Romans: Practice"
        sermonLink="/phoenix/sermons/interlude"
        date="January 23, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="Hope for Humanity"
        sermonSeries="Romans: Practice"
        sermonLink="/phoenix/sermons/hope-for-humanity"
        date="January 16, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={Romans}
        imageAlt="Romans Series"
        sermonTitle="A Changed Christian"
        sermonSeries="Romans: Practice"
        sermonLink="/phoenix/sermons/a-changed-christian"
        date="January 9, 2022"
        pastor="Pastor Andrew"
      />
      <SermonBox
        imageUrl={More}
        imageAlt="view more sermons"
        sermonTitle="View More Sermons"
        sermonLink="/phoenix/sermons"
        sermonSeries="All Sermons"
        pastor="Cross Church Phoenix"
      />
    </Grid>
  </Layout>
)

export default IndexPage
